@import '../node_modules/modern-normalize/modern-normalize.css';
@import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';

/* Boostrap breakpoints https://getbootstrap.com/docs/5.2/layout/breakpoints/ */

/*
 * Common styles
 */

:root {
    --primary-color: #8A4DFF;
    --body-text-color: rgba(10, 10, 10, 0.9);
}

html {
    font-size: 16px;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--body-text-color);
}

a {
    text-decoration: none;
    transition: 0.3s;
    color: var(--primary-color);
}

.title {
    margin-top: 0;
    margin-bottom: 35px;
    text-transform: uppercase;
}

section.is-primary-section {
    background-color: var(--primary-color);
    color: white;
}

/*
 * Navigation
 */

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: white;
    border-bottom: 1px solid #E5E5E5;
}

.navbar__container {
    height: 66px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.navbar__logo {
    width: 180px;
    height: 50px;
}

.navbar__menu {
    display: flex;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    padding: 10px 0;
    flex-flow: column;
    align-items: center;
    background-color: white;
    border-radius: 0 0 4px 4px;
    border: 1px solid #E5E5E5;
    list-style-type: none;
}

.navbar__menu-item {
    text-transform: uppercase;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
}

.navbar__menu-item a {
    color: var(--body-text-color);
}

.navbar__menu-item_active a {
    color: var(--primary-color);
}

.navbar__menu-item a:link,
.navbar__menu-item a:visited {
    color: black;
}

.navbar__menu-item a:hover {
    color: var(--primary-color);
}

.navbar__language {
    width: 25px;
    height: 25px;
    display: inline-flex;
    cursor: pointer;
}

.navbar__hamburger-menu {
    width: 30px;
    height: 30px;
    display: none;
    cursor: pointer;
}

.navbar__cancel {
    width: 20px;
    height: 20px;
    display: none;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .navbar__menu {
        padding: 0;
        flex-flow: row;
        position: static;
        width: auto;
        border-radius: 0;
        background-color: inherit;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
    }

    .navbar__menu {
        border: none;
    }
}

@media screen and (min-width: 992px) {
    .navbar__logo {
        width: 240px;
    }
}

/*
 * Home
 */

.home {}

.home__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home__avatar {
    width: 250px;
    height: 250px;
}

.home__titles > h1 {
    font-weight: 500;
}

.home__titles > h3 {
    font-weight: 300;
    text-transform: uppercase;
}

#home-scroll-down {
    width: 75px;
    height: 75px;
}

@media screen and (min-width: 768px) {
    .home__avatar {
        width: 300px;
        height: 300px;
    }
}

/*
 * Expertise
 */

.expertise {
    text-align: center;
}

.expertise__container {
    padding: 40px 0 40px 0;
}

.expertise__skills {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px;
    flex-wrap: nowrap;
    grid-gap: 8px;
}

.expertise__skill {
    width: 100%;
    border-radius: 8px;
    background-color: white;
    color: var(--body-text-color);
}

.expertise__skill div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.expertise__skill img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.expertise__skill h3 {
    text-align: left;
}

.expertise__skill p {
    text-align: center;
    padding: 0 16px 0 16px;
    line-height: 24px;
}

.expertise__angular {
    color: #DD1031;
}

.expertise__react {
    color: #327EA4;
}

.expertise__nodejs {
    color: #1D6E00;
}

.expertise__nestjs {
    color: #EA2846;
}

.expertise__skill_assessment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-weight: bold;
    padding-right: 8px;
    padding-left: 8px;
}

.expertise__skill_assessment a {
    color: white;
    font-weight: bold;
}

.expertise__skill_assessment svg {
    width: 25px;
    height: 25px;
}

@media screen and (min-width: 768px) {
    .expertise__container {
        padding: 80px 0 80px 0;
    }

    .expertise__skills {
        flex-direction: row;
    }

    .expertise__skill_assessment {
        flex-direction: row;
    }
}

/*
 * Timeline
 */

.timeline {}

.timeline__container {
    padding: 40px 0 40px 0;
}

.timeline__title {
    text-align: center;
    margin-bottom: 50px;
}

.timeline__list {
    margin: 0;
    padding: 0;
    position: relative;
}

.timeline__list:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: var(--primary-color);
}

.timeline__list-item {
    list-style-type: none;
    position: relative;
    margin-bottom: 25px;
}

.timeline__list .timeline__list-item:last-child {
    margin-bottom: 0;
}

.timeline__panel {
    width: 100%;
    padding: 0 20px 20px 80px;
}

.timeline__list .timeline__list-item:last-child .timeline__panel {
    padding-bottom: 0;
}

.timeline__panel > h4 {
    margin-top: 0;
    text-transform: uppercase;
}

.timeline__panel > p {
    margin: 0;
    line-height: 20px;
}

.timeline__image {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
}

.timeline__list .timeline__list-item:last-child .timeline__image {
    top: auto;
    bottom: -5px;
}

.timeline__image > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid var(--primary-color);
}

@media screen and (min-width: 768px) {
    .timeline__container {
        padding: 80px 0 80px 0;
    }

    .timeline__list:before {
        width: 4px;
        left: 50%;
    }

    .timeline__list-item {
        min-height: 170px;
        margin-bottom: 100px;
    }

    .timeline__list .timeline__list-item:last-child {
        margin-bottom: 0;
    }

    .timeline__panel {
        width: 41%;
        text-align: right;
        padding: 0 20px 20px 100px;
    }

    .timeline__list-item-reverted {
        display: flex;
        justify-content: flex-end;
    }

    .timeline__list-item-reverted .timeline__panel {
        text-align: left;
        padding: 0 100px 20px 20px;
    }

    .timeline__image {
        left: calc(50% - 75px);
    }

    .timeline__image > img {
        width: 150px;
        height: 150px;
        border: 8px solid var(--primary-color);
    }
}

/*
 * Contact
 */

.contacts {}

.contacts__container {
    padding: 40px 0 40px 0;
    text-align: center;
    color: white;
}

.contacts__text {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 35px;
}

.contacts__social-networks {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.contacts__social-network {
    list-style-type: none;
}

.contacts__social-network {}

.contacts__social-network span {
    width: 45px;
    height: 45px;
    margin: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    transition: 0.3s;
}

.contacts__social-network span > svg {
    color: white;
    width: 20px;
    height: 20px;
    transition: color 0.3s ease;
}

.contacts__social-network span:hover {
    background-color: white;
}

.contacts__social-network span:hover > svg {
    color: var(--primary-color);
}

@media screen and (min-width: 768px) {
    .contacts__container {
        padding: 80px 0 80px 0;
    }
}
